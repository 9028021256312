import { useState } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Chip, Box, Stack, Button } from "@mui/material";

import Topics from "../../assets/content/topics.json";

function TopicChips({ tags, light }) {
  const [subtopicVisible, setSubtopicVisible] = useState(false);
  const tagToSubtag = {};
  tags?.forEach((tag) => {
    const tokens = tag.split(" :: ");
    const topic = tokens[0];
    if (!tagToSubtag[topic]) {
      tagToSubtag[topic] = [];
    }

    if (tokens[1]) {
      tagToSubtag[topic].push(tokens[1]);
    }
  });

  const orderedTags = [];
  Object.keys(tagToSubtag)
    .sort()
    .forEach((topic) => {
      orderedTags.push({ tag: topic, child: false });
      tagToSubtag[topic]
        .sort()
        .forEach((subTopic) =>
          orderedTags.push({ tag: subTopic, child: true })
        );
    });

  const hasChildren = orderedTags.find((tag) => tag.child);

  return (
    <Grid container item xs={12} paddingTop={2}>
      <Stack sx={{ flexWrap: "wrap" }} direction="row">
        {orderedTags
          .filter(({ child }) => (subtopicVisible ? true : !child))
          .map(({ tag, child }) => {
            return (
              <Box
                component="span"
                paddingTop={1}
                paddingRight={1}
                key={`topic-chips-${tag}`}
              >
                <Chip
                  label={tag}
                  size="small"
                  variant={child ? "outlined" : "filled"}
                  sx={{
                    backgroundColor: !child
                      ? Topics.find(
                          (topic) =>
                            topic.label.toLowerCase() === tag.toLowerCase()
                        ).primaryColor
                      : "none",
                    color: child && !light ? "black" : "white",
                  }}
                />
              </Box>
            );
          })}
        {hasChildren && (
          <Button
            onClick={() => setSubtopicVisible(!subtopicVisible)}
            size="small"
            sx={{ paddingTop: ".7rem" }}
          >
            <Typography variant="caption" color={light ? "#CCC" : "#8A8A8A"}>
              {`${subtopicVisible ? "Hide" : "Show"}`} Subthemes
            </Typography>
          </Button>
        )}
      </Stack>
    </Grid>
  );
}

export default TopicChips;

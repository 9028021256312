import { Grid, Typography } from "@mui/material";

import StyledBackground from "../../common/background/StyledBackground";

function Participants({ config, offset }) {
  return (
    <Grid container xs={12} direction="row" marginTop={offset}>
      <Grid
        item
        md={7}
        sx={{
          color: "white",
          width: "100%",
          minHeight: "55vh",
          backgroundImage: `url(${config.picture})`,
          backgroundSize: "cover",
        }}
      />
      <StyledBackground md={5} item padding texture light minHeight="55vh">
        <Typography
          variant="h5"
          component="div"
          sx={{ color: "#FFF", paddingBottom: ".5rem" }}
        >
          {config.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "#FFF", paddingTop: "2rem", paddingBottom: "3rem" }}
        >
          {config.body}
        </Typography>
      </StyledBackground>
    </Grid>
  );
}

export default Participants;

import { Grid, Typography } from "@mui/material";
import StyledCallout from "../../common/callouts/StyledCallout";

function Introduction({ config }) {
  return (
    <StyledCallout offset texture light>
      <Grid container padding={3}>
        <Grid item md={5}>
          <Typography variant="h5">{config.title}</Typography>
        </Grid>
        <Grid item md={7} paddingLeft={{ md: 3 }}>
          {config.body}
        </Grid>
      </Grid>
    </StyledCallout>
  );
}

export default Introduction;

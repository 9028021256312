import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router";

import TopicNav from "./TopicNav";
import SummaryContainer from "./SummaryContainer";
import SummaryContainerMobile from "./SummaryContainerMobile";
import SplitBackgroundGrid from "../common/background/SplitBackgroundGrid";
import StyledBanner from "../common/banners/StyledBanner";

import { OVERVIEW } from "./config/config";

function TopicViewContainer({ topics, highlights }) {
  const [currSection, setCurrSection] = useState("Overview");

  const { id } = useParams();
  const topicInfo = topics.find((topic) => topic.id === id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (section) => {
    setCurrSection(section);
  };

  const handleChangeDropdown = (event) => {
    setCurrSection(event.target.value);

    const subTopicInfo = topicInfo.subTopics.find(
      (subTopic) => subTopic.label === event.target.value
    );

    const element = document.querySelector(`#mobile-${subTopicInfo.link}`);
    const topPos =
      element.getBoundingClientRect().top + window.pageYOffset - 80;

    window.scrollTo({
      top: topPos,
      behavior: "smooth",
    });
  };

  const handleEnterWaypoint = (section) => {
    setCurrSection(section);
  };

  return (
    <Grid>
      <StyledBanner
        title={topicInfo.label}
        body={topicInfo.preview}
        picture={OVERVIEW.banners[topicInfo.id].src}
        navigation={OVERVIEW}
      />
      <TopicNav
        topics={topicInfo.subTopics}
        onClick={handleClick}
        onChange={handleChangeDropdown}
        currSection={currSection}
      />
      <Grid sx={{ display: { xs: "none", md: "block" } }}>
        <SplitBackgroundGrid>
          <SummaryContainer
            topicInfo={topicInfo}
            currSection={currSection}
            highlights={highlights}
            onEnterWaypoint={handleEnterWaypoint}
            sectionId="screen"
          />
        </SplitBackgroundGrid>
      </Grid>
      <Grid
        xs={12}
        paddingBottom={10}
        sx={{ backgroundColor: "white", display: { xs: "block", md: "none" } }}
      >
        <SummaryContainerMobile
          topicInfo={topicInfo}
          currSection={currSection}
          highlights={highlights}
          onEnterWaypoint={handleEnterWaypoint}
          sectionId="mobile"
        />
      </Grid>
    </Grid>
  );
}

export default TopicViewContainer;

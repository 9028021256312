import { Grid, Container } from "@mui/material";
import { TEXTURE } from "../../../config";

const Section = ({ children, texture, dark }) => {
  return (
    <Grid
      container
      item
      paddingBottom={6}
      marginBottom={11}
      xs={12}
      sx={{
        height: "100%",
        backgroundImage: texture ? `url(${TEXTURE})` : "unset",
        backgroundSize: "cover",
        paddingBottom: "2rem",
        backgroundColor: dark ? "primary.light" : "unset",
      }}
    >
      <Container maxWidth="lg">{children}</Container>
    </Grid>
  );
};

export default Section;

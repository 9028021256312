import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import LinkButton from "../../common/buttons/LinkButton";
import StyledBackground from "../../common/background/StyledBackground";

function ParticipantOverviewContainer({ config, minHeight }) {
  return (
    <Grid container xs={12}>
      <Grid
        item
        xs={12}
        md={7}
        sx={{
          color: "white",
          width: "100%",
          minHeight: "55vh",
          backgroundImage: `url(${config.picture})`,
          backgroundSize: "cover",
        }}
      />
      <StyledBackground
        md={5}
        item
        padding
        texture
        light
        minHeight={ minHeight ? "50vh" : null}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{ color: "#FFF", paddingBottom: ".5rem" }}
        >
          {config.title}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "#FFF", paddingTop: "2rem", paddingBottom: "3rem" }}
        >
          {config.body}
        </Typography>
        <LinkButton link="/about/team">Learn More</LinkButton>
      </StyledBackground>
    </Grid>
  );
}

export default ParticipantOverviewContainer;

import Team from "../../../assets/about/team.jpg";
import Process from "../../../assets/about/process.jpg";

export const OVERVIEW = {
  title: "About",
  link: "/about",
  description:
    "Learn more about the sensemaking process behind this portal and the team that helped us create it.",
  cards: [
    {
      title: "Our JA Youth Advisors",
      body: "Throughout this portal, you’ll see the pictures and names of the 200+ Youth Advisors, alumni from 64 countries who shared their experiences in 45+ small group conversations, and contributed their voices to strengthen the JA strategic plan and share a powerful message about JA values and direction.",
      button: "Learn More",
      link: "team",
      picture: Team,
    },
    {
      title: "Our Process",
      body: "Every three years, JA Worldwide develops the strategic plan that guides our growth and priorities. For the first time, JA Worldwide invited young people to add their voices as part of an advisory group and share their JA experiences. JA Worldwide has partnered with Cortico and the MIT Center for Constructive Communication to provide tools to highlight, analyze, and share these conversations.",
      button: "Learn More",
      link: "process",
      picture: Process,
    },
  ],
};

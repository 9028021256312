import { Grid } from "@mui/material";

import LocationOverviewContainer from "./LocationOverview/LocationOverviewContainer";
import TopicsOverviewContainer from "./TopicsOverview/TopicsOverviewContainer";
import HighlightOverviewContainer from "./HighlightOverview/HighlightOverviewContainer";
import ParticipantOverviewContainer from "./ParticipantOverview/ParticipantOverviewContainer";

import Callout from "../common/callouts/Callout";
import HeaderAndMedia from "../common/text/HeaderAndMedia";
import SplitBanner from "../common/banners/SplitBanner";

import {
  LANDING,
  HIGHLIGHT,
  TOPIC,
  PARTICIPANT,
  LOCATION,
  CTA,
} from "./config/config";

function Landing({ topics }) {
  return (
    <Grid container sx={{ mt: 0 }}>
      <SplitBanner
        title={LANDING.title}
        body={LANDING.description}
        picture={LANDING.picture}
        topicsConfig={TOPIC}
        texture
        light
        large
        arrow
      />

      <Grid
        container
        item
        marginTop={{ sm: 4, md: 6 }}
        marginBottom={{ sm: 5, md: 8 }}
      >
        <Callout>
          <TopicsOverviewContainer topics={topics} config={TOPIC} margin />
        </Callout>
      </Grid>

      <Grid container item xs={12} marginBottom={{ xs: 6, md: 14 }}>
        <HighlightOverviewContainer topics={topics} config={HIGHLIGHT} />
      </Grid>

      <Grid container item xs={12}>
        <HeaderAndMedia
          title={CTA.title}
          body={CTA.body}
          link={CTA.link}
          media={CTA.media}
          transparent
          swap
        />
      </Grid>

      <Grid
        container
        item
        marginTop={{ sm: 4, md: -2 }}
        marginBottom={{ sm: 2, md: 8 }}
      >
        <Callout offset="-150px">
          <LocationOverviewContainer config={LOCATION} />
        </Callout>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sx={{ display: { xs: "none", sm: "block", md: "none" } }}
      >
        <Callout>
          <ParticipantOverviewContainer config={PARTICIPANT} minHeight />
        </Callout>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sx={{ display: { xs: "block", sm: "none", md: "block" } }}
      >
        <ParticipantOverviewContainer config={PARTICIPANT} minHeight />
      </Grid>
    </Grid>
  );
}

export default Landing;

import Grid from "@mui/material/Grid";
import { TEXTURE } from "../../../config";

function StyledBackground({
  children,
  picture,
  md,
  offset,
  item,
  padding,
  texture,
  light,
  minHeight,
}) {
  return (
    <Grid
      container
      xs={12}
      md={md}
      paddingX={{ xs: padding ? 5 : 0, sm: padding ? 8 : 0 }}
      paddingY={padding ? 10 : 4 }
      sx={{
        minHeight: { md: minHeight ?? "100%" },
        backgroundImage: picture
          ? `url(${picture})`
          : texture
          ? `url(${TEXTURE})`
          : "unset",
        backgroundSize: "cover",
        color: light ? "white" : "primary.main",
        backgroundColor: "primary.dark",
        marginTop: offset ? "-100px" : "unset",
      }}
    >
      {item && <Grid item>{children}</Grid>}
      {!item && children}
    </Grid>
  );
}

export default StyledBackground;

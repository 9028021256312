import { Grid } from "@mui/material";
import { Waypoint } from "react-waypoint";

import SummaryBlock from "./SummaryBlock";

function SummaryContainerMobile({
  topicInfo,
  highlights,
  onEnterWaypoint,
  sectionId,
}) {
  return (
    <Grid xs={12}>
      {topicInfo.subTopics.map((subTopic) => (
        <Waypoint
          onEnter={() => onEnterWaypoint(subTopic.label)}
          topOffset="30%"
          bottomOffset="60%"
        >
          <div>
            <SummaryBlock
              key={`summary-container-${sectionId}-${subTopic.label}`}
              title={subTopic.label}
              text={subTopic.text}
              link={subTopic.link}
              primaryColor={topicInfo.primaryColor}
              secondaryColor={topicInfo.secondaryColor}
              highlights={highlights}
              sectionId={sectionId}
              popUp
            />
          </div>
        </Waypoint>
      ))}
    </Grid>
  );
}

export default SummaryContainerMobile;

import { MAP } from "../../../config";

import entities from "../../../assets/content/entities.json";
import Regions from "../../../assets/voices-page/regions.png";
import Conversations from "../../../assets/voices-page/conversations.png";

export const OVERVIEW = {
  title: "Voices",
  link: "/voices",
  description:
    "Explore the collection of 1,190+ highlights from 200+ JA youth voices by region or conversation.",
  cards: [
    {
      title: `By ${MAP.label}`,
      body: "The youth voices map is a tool to explore across conversations by geography. Navigate using six different JA Regions to hear and visualize highlights from youth alumni.",
      button: "Explore JA Worldwide",
      link: "map-explorer",
      picture: Regions,
    },
    {
      title: "By Conversation",
      body: "Library of Conversations is a data visualization of collected conversations. Explore the most common themes and listen to connected highlights.",
      button: "Explore Conversations",
      link: "conversation-explorer",
      picture: Conversations,
    },
  ],
};

export const CONVERSATIONS = {
  data: {
    tags: [
      "self_efficacy",
      "impact",
      "learning_experiences",
      "thriving_communities",
      "youth_empowerment",
      "values",
    ],
    entities: entities,
  },
  styles: {
    tagsToColor: {
      self_efficacy: "self-efficacy",
      impact: "impact",
      learning_experiences: "learning-experiences",
      thriving_communities: "thriving-communities",
      youth_empowerment: "youth-empowerment",
      values: "values",
    },
  },
};

import Button from "@mui/material/Button";
import Play from "@mui/icons-material/PlayArrow";
import Pause from "@mui/icons-material/Pause";

function PlayButton({ info, isPlaying, onClick, small }) {
  const fontStyles = { paddingRight: ".5rem", fontSize: "16px" };
  return (
    <Button
      variant="contained"
      size={small ? "small" : "medium"}
      onClick={onClick}
      sx={{
        width: small ? "100%" : "unset",
        fontWeight: "500",
        marginTop: ".15rem",
        fontSize: "12px",
        backgroundColor: isPlaying ? "primary.light" : "primary.dark",
        ":hover": {
          backgroundColor: "primary.main",
        },
      }}
      disableElevation
    >
      {isPlaying ? (
        <Pause sx={fontStyles} />
      ) : (
        <Play sx={fontStyles} />
      )}{" "}
      {info}
    </Button>
  );
}

export default PlayButton;

import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

export default function LinkButton({ link, children, white, size }) {
  const buttonStyles = white ? { color: "white", borderColor: "white" } : {};
  return (
    <Link
      to={link}
      style={{
        textDecoration: "none",
      }}
    >
      <Button
        variant="contained"
        disableElevation
        color="secondary"
        size={size}
        sx={{
          ...buttonStyles,
          ":hover": {
            color: "white",
          },
        }}
      >
        {children}
      </Button>
    </Link>
  );
}

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import HighlightCarousel from "../../common/carousels/HighlightCarousel";
import LinkButton from "../../common/buttons/LinkButton";
import StyledBackground from "../../common/background/StyledBackground";

function HighlightInfo({ highlightInfo, highlights, indexes }) {
  return (
    <StyledBackground texture light>
      <Grid item md={4} sx={{ marginTop: "5rem" }} paddingLeft={8}>
        <Typography
          variant="body2"
          sx={{ paddingBottom: "3rem", color: "inherit" }}
        >
          {highlightInfo.text}
        </Typography>
        <LinkButton link={highlightInfo.search}>Hear Highlights</LinkButton>
      </Grid>
      <Grid item md={8} paddingLeft="5rem" sx={{ marginTop: "5rem" }}>
        {highlights && (
          <HighlightCarousel
            highlights={highlights}
            indexes={indexes}
            id="normal"
          />
        )}
      </Grid>
    </StyledBackground>
  );
}

export default HighlightInfo;

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ORGANIZATION } from "../../config";

function Footer({ extraInfo }) {
  return (
    <Grid
      container
      sx={{
        paddingTop: ".6rem",
        paddingBottom: ".2rem",
        paddingX: {xs: ".25rem", md: ".5rem"},
        background: "#F5F5F5",
      }}
    >
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          paddingBottom: ".5rem",
          paddingLeft: "1rem",
        }}
      >
        <img
          style={{
            height: "14.2px",
            verticalAlign: "top",
          }}
          alt="Logo"
          src={ORGANIZATION.small}
        />
      </Grid>
      <Grid item xs={9} sx={{ display: "flex", justifyContent: "right" }}>
        <Typography
          sx={{
            fontSize: "10px",
            paddingRight: "1.2rem",
            textTransform: "capitalize",
          }}
        >
          <i>{extraInfo}</i>
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;

import { useEffect } from "react";
import { Grid, Container } from "@mui/material";

import StyledBanner from "../common/banners/StyledBanner";
import TopicCard from "./TopicCard";

import { OVERVIEW } from "./config/config";

function Insights({ topics }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Grid container>
      <StyledBanner title={OVERVIEW.title} body={OVERVIEW.body} texture light />

      <Container maxWidth="lg">
        <Grid
          container
          item
          spacing={5}
          paddingX={{ xs: 3, md: 0 }}
          paddingY={10}
        >
          {topics.map((topic) => (
            <Grid sm={6} md={4} item>
              <TopicCard
                topicId={topic.id}
                topicInfo={topic}
                picture={OVERVIEW.banners[topic.id]?.src}
                preview
                small
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
}

export default Insights;

import Step1 from "../../../assets/about/Our Process_1.png";
import Step2 from "../../../assets/about/Our Process_2.png";
import Step3 from "../../../assets/about/Our Process_3.png";

import Listening from "../../../assets/about/lvn.png";
import Analyzing from "../../../assets/about/insights.png";
import Synthesizing from "../../../assets/about/results.png";

export const INTRODUCTION = {
  "title": "How does this work?",
  "body": "Every three years, JA Worldwide develops the strategic plan that guides our growth and priorities. For the first time, JA Worldwide invited young people to add their voices as part of an advisory group and share their JA experiences. JA Worldwide has partnered with Cortico and the MIT Center for Constructive Communication to provide tools to highlight, analyze, and share these conversations. The original conversations are now presented on Youth Voices as Year 1. Year 2 brought together even more JA alumni to share their hopes and dreams for the future and their visions for the world."
}

export const STEPS = [
  {
    "title": "Facilitated Conversations",
    "picture": Step1,
    "body": "We start exploring this space by inviting 100+ JA students and alumni across 45+ countries and all six JA regions to join recorded small-group conversations with 3-4 participants. Ιn these conversations, we invite them to share their stories and experiences around four questions:",
    "sections": [
      {
        "label": "JA Experiences",
        "text": "Reflect on your current experience with JA; tell us how the pandemic has shaped your experience as a learner."
      },
      {
        "label": "Community Supports",
        "text": "These times have been difficult for many.",
        "list": ["How has your community been affected?", "To what extent has your community received the supports that it needed?"],
        "bullet": true
      },
      {
        "label": "Future Visions",
        "text": "Close your eyes and picture your future 5 or 10 years from now.",
        "list": ["What do you hope it looks like?", "What challenges do you foresee?", "Which tools/skills did you develop as a JA member/alumni that can help you achieve your goals in that future?"],
        "bullet": true
      },
      {
        "label": "Takeaways",
        "text": "For our last question, we invite you to share one thing you heard today that you’ll be taking away from this conversation and that you’d like other people to hear."
      }
    ]
  },
  {
    "title": "Sensemaking",
    "picture": Step2,
    "body": "After the conversations are facilitated and recorded, they get uploaded on our conversation platform and become part of a community-protected and community-owned collection. The sensemaking team at Cortico and the MIT Center for Constructive Communication actively engage with the 25 conversations to analyze, make meaning, and thematically organize the stories and experiences that were shared during the conversations.",
    "sections": [
      {
        "label": "Listening",
        "picture": Listening,
        "text": "We start by listening deeply to all conversations and immerse ourselves into the conversation collection. As we listen, we systematically identify and surface the themes that participants shared in the facilitated conversations."
      },
      {
        "label": "Analyzing",
        "picture": Analyzing,
        "text": "We collaboratively work together with the JA leadership team and built on each other’s knowledge, expertise, and skills to learn from one another and share nuanced insights into the experiences we are listening to. Our guiding questions were:",
        "list": ["How might we analyze the rich and complex stories and experiences of the JA students and alumni and make sense of those data?", "How can we uplift how the lived experiences shared in these conversations reflect the mission, the objectives, and the values of JA?"],
        "bullet": true
      },
      {
        "label": "Synthesizing",
        "picture": Synthesizing,
        "text": "Using tools and methods developed at the MIT Center for Constructive Communication, we identified themes and patterns in the conversations and mapped related experiences to six thematic categories:",
        "list": ["Self-Efficacy", "Impact", "Learning Experiences", "Thriving Communities", "Youth Empowerment", "Values"],
        "bullet": true,
        "small": true
      }
    ],
    "label": "Step"
  },
  {
    "title": "Conversation Portal",
    "picture": Step3,
    "body": "The conversation portal is an interactive, public-facing platform to explore and listen to the stories and experiences of the JA youth in a thematically organized way. Experiences and stories can be explored by theme and JA region. After the analysis, we provide context for each theme and subtheme by summarizing what we heard in the conversations and translating themes into patterns.<p>We weave into the thematic summaries representative highlights of the conversations to provide more nuanced insights into each theme and lift up participants’ voices who reflect on their lived experiences. Anyone can use the portal to listen and better understand the hopes, concerns, and experiences of JA Worldwide students and alumni.</p><p>Interested in learning more about our approach? Contact us at <a href='mailto:hello@cortico.ai?subject=Hello'>hello@cortico.ai</a>.</p>"
  }
]
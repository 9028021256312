import LearningExperiences from "../../../assets/insights-page/learning-experiences-aqua.png";
import Values from "../../../assets/insights-page/values-aqua.png";
import YouthEmpowerment from "../../../assets/insights-page/youth-empowerment-aqua.jpg";
import SelfEfficacy from "../../../assets/insights-page/self-efficacy-aqua.png";
import ThrivingCommunities from "../../../assets/insights-page/thriving-communities-aqua.jpg";
import Impact from "../../../assets/insights-page/impact-aqua.jpg";

export const OVERVIEW = {
  title: "Insights",
  link: "/insights",
  body:
    "This portal shares the insight and impact of JA students and alumni, based on their learning experiences.",
  banners: {
    "learning-experiences": {
      src: LearningExperiences,
    },
    values: {
      src: Values,
    },
    "youth-empowerment": {
      src: YouthEmpowerment,
    },
    "thriving-communities": {
      src: ThrivingCommunities,
    },
    "self-efficacy": {
      src: SelfEfficacy,
    },
    impact: {
      src: Impact,
    },
  },
};

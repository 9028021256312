import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { MAP } from "../../../config";
import Locations from "../../../assets/content/locations.json";
import InfoModalButton from "../../common/buttons/InfoModalButton";
import SectionHeader from "../../common/banners/SectionHeader";
import MultipleCheckFilter from "../../common/filters/MultipleCheckFilter";
import SingleRadioFilter from "../../common/filters/SingleRadioFilter";

import { Tune } from "@mui/icons-material";

import MapView from "./MapView";
import ListView from "./ListView";
import {
  useQueryArg,
  MAP_QUERY_ARGS,
  getParentTagFromTagString,
  CONVERSATION_YEAR,
} from "../../../utils/utils";

import Speakers from "../../../assets/content/speakers.json";

function MapViewContainer({ topics, prompts, highlights }) {
  const [
    filteredHighlightsWithLocationFilter,
    setFilteredHighlightsWithLocationFilter,
  ] = useState(highlights);
  const [
    filteredHighlightsWithoutLocationFilter,
    setFilteredHighlightsWithoutLocationFilter,
  ] = useState(highlights);

  const [currLocation, setCurrLocation] = useState(
    useQueryArg(MAP_QUERY_ARGS.location) ?? null
  );

  const [promptFilter, setPromptFilter] = useState(
    useQueryArg(MAP_QUERY_ARGS.prompt) ?? null
  );

  const [isMapOn, setIsMapOn] = useState(
    useQueryArg(MAP_QUERY_ARGS.hideMap) ? false : true
  );

  const topicQueryArg = useQueryArg(MAP_QUERY_ARGS.topic);
  const topicQueryInfo = topics.find((topic) => topic.label === topicQueryArg);
  const [topicFilters, setTopicFilters] = useState(
    topicQueryArg ? [topicQueryInfo] : []
  );

  // year filter
  const [yearFilter, setYearFilter] = useState(
    useQueryArg(MAP_QUERY_ARGS.year) ?? null
  );

  useEffect(() => {
    document.getElementById("highlight-scroll").scrollTop = 0;
  }, [filteredHighlightsWithLocationFilter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (region) => {
    if (currLocation === region) {
      setCurrLocation(null);
    } else {
      setCurrLocation(region);
    }
  };

  const handleClose = () => {
    setCurrLocation(null);
  };

  const handleMapToggle = () => {
    setIsMapOn(!isMapOn);
  };

  const handleDeleteTopic = (item) => {
    setTopicFilters(topicFilters.filter((x) => x !== item));
  };

  const handleDeletePrompt = () => {
    setPromptFilter(null);
  };

  useEffect(() => {
    let filtered = highlights;

    if (topicFilters.length > 0) {
      for (let topic of topicFilters) {
        filtered = filtered.filter((highlight) => {
          const tags = highlight.tags;
          for (var tag of tags) {
            const parent = getParentTagFromTagString(tag);
            if (topic.label.toUpperCase() === parent.toUpperCase()) return true;
          }
          return false;
        });
      }
    }

    if (promptFilter) {
      filtered = filtered.filter(
        (highlight) => promptFilter === highlight.prompt
      );
    }

    setFilteredHighlightsWithoutLocationFilter(filtered);

    if (currLocation) {
      filtered = filtered.filter((highlight) => {
        const speakerInfo = Speakers.find((speaker) => {
          if (speaker.name === highlight.speaker)
            return speaker.name === highlight.speaker;

          return false;
        });

        return (
          speakerInfo?.demographics[MAP.demographicId].toLowerCase() ===
          currLocation?.toLowerCase()
        );
      });
    }

    setFilteredHighlightsWithLocationFilter(filtered);

    // filter by year
    if (yearFilter) {
      const conversations = CONVERSATION_YEAR[yearFilter];
      filtered = filtered.filter((highlight) => {
        return conversations.includes(highlight.conversation_id);
      });
    }

    setFilteredHighlightsWithLocationFilter(filtered);
  }, [highlights, currLocation, promptFilter, topicFilters, yearFilter]);

  return (
    <Grid>
      <Grid container>
        <SectionHeader
          title={`Voices By ${MAP.label}`}
          info={
            <InfoModalButton
              title="Filters"
              icon={
                <span style={{ position: "relative", top: "-4.25px" }}>
                  <Tune
                    sx={{
                      fontSize: "20px",
                      position: "relative",
                      top: "5px",
                    }}
                  />
                  <span style={{ paddingLeft: ".5rem" }}>Filters</span>
                </span>
              }
              buttonLabel="Close"
            >
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <SingleRadioFilter
                  items={[
                    { label: `All ${MAP.label}s`, id: null },
                    ...Locations,
                  ]}
                  defaultName={`${MAP.label}s`}
                  selectedItem={currLocation}
                  setSelectedItem={setCurrLocation}
                />
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <SingleRadioFilter
                  items={[
                    { label: `All ${MAP.label}s`, id: null },
                    ...Locations,
                  ]}
                  defaultName={`${MAP.label}s`}
                  selectedItem={currLocation}
                  setSelectedItem={setCurrLocation}
                  singleColumn
                />
              </Box>
              {prompts && (
                <SingleRadioFilter
                  items={[{ label: `All Prompts`, id: null }, ...prompts]}
                  defaultName="Prompts"
                  selectedItem={promptFilter}
                  setSelectedItem={setPromptFilter}
                  singleColumn
                />
              )}
              {topics && (
                <>
                  <Box sx={{ display: { xs: "block", sm: "none" } }}>
                    <MultipleCheckFilter
                      items={topics}
                      defaultName="Themes"
                      selectedItems={topicFilters}
                      setSelectedItems={setTopicFilters}
                      singleColumn
                    />
                  </Box>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <MultipleCheckFilter
                      items={topics}
                      defaultName="Themes"
                      selectedItems={topicFilters}
                      setSelectedItems={setTopicFilters}
                    />
                  </Box>
                </>
              )}
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <SingleRadioFilter
                  items={[
                    { label: `All years`, id: null },
                    { label: "Year 2", id: "Year 2" },
                    { label: "Year 1", id: "Year 1" },
                  ]}
                  defaultName="Year Recorded"
                  selectedItem={yearFilter}
                  setSelectedItem={setYearFilter}
                />
              </Box>
              <Box sx={{ display: { xs: "block", sm: "none" } }}>
                <SingleRadioFilter
                  items={[
                    { label: `All years`, id: null },
                    { label: "Year 1", id: "Year 1" },
                    { label: "Year 2", id: "Year 2" },
                  ]}
                  defaultName="Year Recorded"
                  selectedItem={yearFilter}
                  setSelectedItem={setYearFilter}
                  singleColumn
                />
              </Box>
            </InfoModalButton>
          }
        />
        {!isMapOn && (
          <ListView
            currLocation={currLocation}
            topicFilters={topicFilters}
            handleDeleteTopic={handleDeleteTopic}
            promptFilter={promptFilter}
            handleDeletePrompt={handleDeletePrompt}
            highlights={filteredHighlightsWithLocationFilter}
            handleMapToggle={handleMapToggle}
            locationType={MAP.label}
            yearFilter={yearFilter}
          />
        )}
        {isMapOn && (
          <MapView
            currLocation={currLocation}
            topicFilters={topicFilters}
            handleDeleteTopic={handleDeleteTopic}
            promptFilter={promptFilter}
            handleDeletePrompt={handleDeletePrompt}
            highlights={filteredHighlightsWithLocationFilter}
            handleMapToggle={handleMapToggle}
            locations={Locations}
            handleClick={handleClick}
            onClose={handleClose}
            filteredHighlightsByTopic={filteredHighlightsWithoutLocationFilter}
            locationType={MAP.label}
            yearFilter={yearFilter}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default MapViewContainer;

import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

function SingleRadioFilter({
  items,
  defaultName,
  selectedItem,
  setSelectedItem,
  singleColumn,
}) {
  const handleChange = (key) => setSelectedItem(key);
  return (
    <Grid paddingBottom={3}>
      <Typography variant="body1" paddingBottom=".5rem">
        <strong>{defaultName}</strong>
      </Typography>
      <RadioGroup>
        <ul className={singleColumn ? "list-single-column" : "list-two-column"}>
          {items.map((item) => {
            if (item.hide) return;
            return (
              <li>
                <FormControlLabel
                  key={item.id}
                  control={
                    <Radio
                      size="small"
                      checked={selectedItem === item.id}
                      sx={{ paddingY: 0.25 }}
                    />
                  }
                  label={<Typography variant="body2">{item.label}</Typography>}
                  onChange={() => handleChange(item.id)}
                />
              </li>
            );
          })}
        </ul>
      </RadioGroup>
    </Grid>
  );
}

export default SingleRadioFilter;

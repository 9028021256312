import { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";

import CardTabs from "../../common/tabs/CardTabs";
import ColoredTabs from "../../common/tabs/ColoredTabs";

function ProcessCardTabContainer({ config, label }) {
  const [selectedIdx, setSelectedIdx] = useState(1);
  const [selected, setSelected] = useState(config[selectedIdx - 1].label);
  const [body, setBody] = useState(config[selectedIdx - 1]);

  const handleClickCardTab = (idx) => {
    setSelectedIdx(idx);
    setBody(config[idx - 1]);
  };

  const handleChangeTab = (event, label) => {
    setSelected(label);
    setBody(config.find((item) => item.label === label));
  };

  const hasPicture = body.picture;

  return (
    <>
      <Grid sx={{ display: { xs: "none", sm: "block" } }}>
        <CardTabs
          items={config}
          selectedIdx={selectedIdx}
          onClick={handleClickCardTab}
          incrementLabel={label}
          small
        />
      </Grid>
      <Grid marginBottom={1} sx={{ display: { xs: "block", sm: "none" } }}>
        <ColoredTabs
          items={config}
          selected={selected}
          onChange={handleChangeTab}
          scroll
          small
          noCircle
        />
      </Grid>

      {!hasPicture && (
        <Box paddingX={3} paddingTop={8} paddingBottom={2}>
          <Grid item xs={12} sm={9}>
            <Typography variant="h6" color="white">
              {body.text}
            </Typography>
            {body.list && (
              <Typography
                variant="h6"
                color="white"
                sx={{
                  fontStyle: "italic",
                }}
              >
                <ul>
                  {body.list.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </Typography>
            )}
          </Grid>
        </Box>
      )}
      {hasPicture && (
        <Grid
          container
          paddingX={3}
          paddingTop={8}
          paddingBottom={6}
          spacing={6}
        >
          <Grid item xs={12} sm={body.small ? 4 : 7}>
            <img src={body.picture} width="100%" />
          </Grid>
          <Grid item xs={12} sm={body.small ? 8 : 5}>
            <Typography variant="body2" color="white">
              {body.text}
            </Typography>
            {body.list && (
              <Typography
                variant="body2"
                color="white"
                paddingTop={4}
                sx={{
                  fontStyle: "italic",
                }}
              >
                <ul>
                  {body.list.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </Typography>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default ProcessCardTabContainer;

import Grid from "@mui/material/Grid";

import HighlightScroll from "./HighlightScroll";

function ListView({
  currLocation,
  topicFilters,
  handleDeleteTopic,
  promptFilter,
  handleDeletePrompt,
  highlights,
  handleMapToggle,
  locationType,
  yearFilter,
}) {
  return (
    <Grid item lg={12} display={{ xs: "block" }}>
      <HighlightScroll
        currLocation={currLocation}
        topicFilters={topicFilters}
        handleDeleteTopic={handleDeleteTopic}
        promptFilter={promptFilter}
        handleDeletePrompt={handleDeletePrompt}
        highlights={highlights}
        sectionId="full"
        isMapShowing={false}
        handleMapToggle={handleMapToggle}
        locationType={locationType}
        yearFilter={yearFilter}
      />
    </Grid>
  );
}

export default ListView;

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function DropdownMenu({ items, selected, label, handleChange }) {
  return (
    <FormControl variant="filled" fullWidth sx={{ backgroundColor: "white" }}>
      <InputLabel id="simple-select-label">{label}</InputLabel>
      <Select
        labelId="simple-select-label"
        id="simple-select"
        value={selected}
        label={label}
        onChange={handleChange}
      >
        {items.map((item) => (
          <MenuItem value={item.label}>{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default DropdownMenu;

import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ColoredTabs from "../../common/tabs/ColoredTabs";
import HighlightInfo from "./HighlightInfo";
import HighlightInfoCompact from "./HighlightInfoCompact";
import Highlights from "../../../assets/content/highlights.json";

function HighlightOverviewContainer({ topics, config }) {
  const [selectedTopic, setSelectedTopic] = useState(topics[0].label);

  // Need to pass through event
  const handleChangeTab = (event, label) => {
    setSelectedTopic(label);
  };

  const handleChangeDropdown = (event) => {
    setSelectedTopic(event.target.value);
  }

  const highlightInfo = config.featured[selectedTopic];

  const [featured, setFeatured] = useState();
  useEffect(() => {
    setFeatured(
      Highlights.filter((h) => highlightInfo.featured.includes(h.id))
    );
  }, [selectedTopic]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          xs={8}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: "2rem",
            display: { xs: "none", md: "block" },
          }}
        >
          <Typography
            textAlign="center"
            variant="h5"
            sx={{ color: "primary.main" }}
          >
            Highlights from Conversations
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        paddingTop={1}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <ColoredTabs
          items={topics}
          selected={selectedTopic}
          onChange={handleChangeTab}
        />
        <HighlightInfo
          highlightInfo={highlightInfo}
          highlights={featured}
          indexes={highlightInfo.featured}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
        <HighlightInfoCompact
          topics={topics}
          selectedTopic={selectedTopic}
          handleChange={handleChangeDropdown}
          highlightInfo={highlightInfo}
          highlights={featured}
          indexes={highlightInfo.featured}
        />
      </Grid>
    </Grid>
  );
}

export default HighlightOverviewContainer;

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

function SectionHeader({ title, info, children }) {
  return (
    <Grid
      item
      xs={12}
      id={`section-${title}`}
      sx={{ backgroundColor: "primary.dark", color: "white" }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          paddingX: { xs: 2, md: 3 },
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        <Grid
          container
          item
          xs={10}
          sx={{ justifyContent: "center", flexDirection: "column" }}
        >
          <Box>
            <Typography
              noWrap
              component="span"
              variant="body1"
              paddingRight={2.25}
              paddingLeft={2}
              sx={{ fontWeight: "600" }}
            >
              {title}
            </Typography>
            {info}
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={2}
          sx={{ justifyContent: "end", paddingRight: "2rem" }}
        >
          {children}
        </Grid>
      </Box>
    </Grid>
  );
}

export default SectionHeader;

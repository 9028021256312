import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";

function ColoredTabs({ items, selected, onChange, scroll, small, noCircle }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={selected}
        onChange={onChange}
        variant={scroll ? "scrollable" : "fullWidth"}
        scrollButtons="auto"
        textColor="#FFF"
        indicatorColor="#FFF"
        centered={!scroll}
      >
        {items.map((item) => {
          return (
            <Tab
              value={item.label}
              icon={
                !noCircle && (
                  <CircleIcon
                    sx={{
                      fontSize: "12px",
                      color: item.primaryColor,
                      paddingRight: ".5rem",
                    }}
                  />
                )
              }
              iconPosition="start"
              label={item.label}
              sx={{
                fontFamily: "Montserrat",
                fontSize: small ? "12px" : "14px",
                textTransform: "capitalize",
                backgroundColor: selected === item.label ? "primary.dark" : "",
                color: selected === item.label ? "#FFF" : "#000",
                borderRadius: "5px 5px 0 0",
              }}
            />
          );
        })}
      </Tabs>
    </Box>
  );
}

export default ColoredTabs;

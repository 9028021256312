import { useEffect } from "react";
import { OVERVIEW } from "./config/config";

import InfoCardView from "../common/cards/InfoCardView";

function Voices() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <InfoCardView config={OVERVIEW} />;
}

export default Voices;

import Landing from "../../../assets/landing-page/audience-aqua.png";
import Participant from "../../../assets/landing-page/mosaic.png";
import Gather from "../../../assets/logos/gather.png";

export const LANDING = {
  title:
    "Youth Voices surfaces firsthand experiences from 200+ JA Worldwide students and alumni across 64 countries.",
  description:
    "Youth Voices is powered by JA Worldwide, Cortico, MIT Center for Constructive Communication (CCC), and Accenture.",
  picture: Landing,
};

// Supplying "background" field sets the background to the specified picture
export const HIGHLIGHT = {
  featured: {
    "Self-Efficacy": {
      featured: [5257850, 5263486, 5262060, 5249595, 5247643, 5249588, 5249503],
      text: "In 580+ highlights across conversations, JA alumni shared experiences moving from ”I can’t” to “I can.” Mastering skills through hands-on experiences came up most frequently as alumni shared stories about real-world learning through their dynamic and diverse JA programs.",
      search: "/voices/map-explorer?hideMap=true&topic=Self-Efficacy",
    },
    Impact: {
      featured: [
        5262301, 5261209, 5257826, 5248931, 5247932, 5248440, 5247967, 5248035,
      ],
      text: "In 640+ highlights across conversations, JA alumni shared stories and future visions connected to JA impact. Experiences of impact were shared more than any other identified theme and global citizen skills—building awareness about the wider world and playing an active role in the global community—came up most frequently.",
      search: "/voices/map-explorer?hideMap=true&topic=Impact",
    },
    "Learning Experiences": {
      featured: [
        5261202, 5261580, 5248731, 5249179, 5247988, 5248033, 5247732, 5248023,
      ],
      text: "In 190+ highlights across conversations, JA alumni shared experiences across digital, in-person, and alumni-specific programs. By far, impact-driven digital experiences came up most frequently as alumni shared their recent experiences in virtual programming during the global pandemic.",
      search: "/voices/map-explorer?hideMap=true&topic=Learning%20Experiences",
    },
    "Thriving Communities": {
      featured: [5261073, 5262073, 5248783, 5248746, 5247330, 5248467, 5248688],
      text: "In 330+ moments across conversations, JA alumni shared stories and dreams connected to their communities at JA Worldwide and locally. The power of connection was often shared in tandem with resilience as a powerful tool to overcome hardship, loss, and devastation.",
      search: "/voices/map-explorer?hideMap=true&topic=Thriving%20Communities",
    },
    "Youth Empowerment": {
      featured: [
        5261593, 5261272, 5248047, 5249241, 5249586, 5249204, 5248712, 5247619,
      ],
      text: "In 475+ moments across conversations, JA alumni shared experiences connected to solving problems, working toward goals, and owning their own economic success. The majority of these highlights were related to setting and achieving goals, affirming the power of JA learning experience, designed to teach students that their goals are within reach.",
      search: "/voices/map-explorer?hideMap=true&topic=Youth%20Empowerment",
    },
    Values: {
      featured: [
        5262290, 5261121, 5247827, 5247149, 5249176, 5247691, 5247956, 5248470,
      ],
      text: "In 415+ moments across conversations, JA alumni shared stories and dreams for the future fortified by JA’s longstanding values. Diversity and inclusion and a strong belief in youth came up most frequently as alumni shared their desire to create sustainable change around the world and in their own communities.",
      search: "/voices/map-explorer?hideMap=true&topic=Values",
    },
  },
};

// Supplying "background" field sets the background to the specified picture
export const STRUCTURE = {
  title: "",
  featured: [
    {
      featured: [5247285, 5247286, 5247287],
      text: "",
      link: "",
      label: "",
    },
  ],
};

export const TOPIC = {
  title: "Six key themes emerged from the 45+ conversations",
  link: "/insights",
  linkLabel: "View Insights",
  body: [
    "After conversations were facilitated and recorded by JA alumni, Cortico and MIT CCC researchers came together and engaged in a rigorous process of sensemaking, using digital and AI-supported tools to highlight and summarize the conversations. In Year 2, three JA alumni sensemakers joined the team, as well.",
    "This public portal shares the insight and impact of JA alumni, based on their learning experiences. These conversations also contributed to JA Worldwide’s strategic planning.",
  ],
  teaser: {
    "Self-Efficacy":
      "<p>This confidence and can-do spirit is expressed by so many JA alumni, including David, from Ghana: “I believe the future is bright, especially with JA in the picture.”</p><p><i>Listen to David’s story and learn more about alumni experiences connected to Self-Efficacy.</i></p>",
    Impact:
      "<p>We hear these dimensions of impact echoed across alumni experiences, including Leong Kit Chau from Hong Kong, who describes the extent of JA’s impact on his life: “So rather than the boring corporate managerial side of business, I'm actually introduced to startup and a concept of entrepreneur. And that actually brings me to who I am today.”</p><p><i>Listen to Leong’s story and learn more about alumni experiences connected to JA Impact.</i></p>",
    "Learning Experiences":
      "<p>Time and again, students like Anouru from Nigeria testify to the open horizons afforded by these experiences: “So the Company Program has actually opened up my horizon around that, made me understand that there's nothing I want to do that I can not do.”</p><p><i>Listen to Anouru’s story and learn more about alumni experiences connected to JA Learning Experiences.</i></p>",
    "Thriving Communities":
      "<p>JA youth like Leandros from Malta tell us about how their communities remained resilient during the pandemic: “We tended to play music out of our window sills, because we have a lot of windows.”</p><p><i>Listen to Leandros’s story and learn more about alumni experiences connected to Thriving Communities.</i></p>",
    "Youth Empowerment":
      "<p>Across conversations, alumni shared visions for their own futures and unpacked the ways in which JA is empowering them to achieve these goals. Marina from Brazil says, “I see myself with my own restaurant, because it’s my dream. And I think JA helped me a lot with teamwork because I’m super shy.”</p><p><i>Listen to Marina’s story and learn more about alumni experiences connected to Youth Empowerment.</i></p>",
    Values:
      "<p>Participants like Cimone from the United States, echo a shared value of humanity and the collective impulse for young people to reach out and help each other: “We're all on different parts of the globe. And I find it interesting that our communities have similar problems, our communities have similar outlooks.”</p><p><i>Listen to Cimone’s story and learn more about alumni experiences connected to JA Values.</i></p>",
  },
};

export const PARTICIPANT = {
  title: "Meet Our JA Youth Advisors",
  body: "200+ JA Youth Advisors across 64 countries participated in the Youth Voices conversations as facilitators and conversation participants.",
  picture: Participant,
};

export const CTA = {
  title:
    "Did JA/INJAZ/YE change your life, too? <a className='jsx-link' href='https://gatheralumni.org' target='_blank'>Join our global alumni community of changemakers.</a>",
  media: Gather,
  link: "https://gatheralumni.org",
};

export const LOCATION = {
  title: "Six JA Regions",
  label: "JA Worldwide",
  link: "/voices/map-explorer",
  body: [
    "Reaching more than 15 million young people each year through nearly half a million teachers and business volunteers, JA Worldwide is one of few organizations with the scale, experience, and passion to build a brighter future for the next generation of innovators, entrepreneurs, and leaders.",
  ],
  descriptions: {
    "JA Africa": {
      title: "JA Africa",
      body: "JA Africa is the regional operating center for JA Worldwide in sub-Saharan Africa. Over the next decade, JA Africa aims to provide one million African youth with information and resources, and to build the self-confidence and self-reliance necessary for Africa’s youth and the subcontinent to succeed.",
      link: "/voices/map-explorer?location=JA%20Africa",
      label: "JA Africa",
    },
    "JA Americas": {
      title: "JA Americas",
      body: "JA Americas is the regional operating center for JA Worldwide in Canada, Latin America, and the Caribbean. JA Americas serves youth across a diverse group of countries through a mix of 30+ distinct programs organized around work readiness, entrepreneurship, and financial health.",
      link: "/voices/map-explorer?location=JA%20Americas",
      label: "JA Americas",
    },
    "JA Asia Pacific": {
      title: "JA Asia Pacific",
      body: "JA Asia Pacific is the regional operating center for JA Worldwide in Asia Pacific. Private and public sector support and on-the-ground delivery of JA programs through 17 JA local areas enable JA Asia Pacific to help young people develop skills for employment and contribute to a strong and capable workforce.",
      link: "/voices/map-explorer?location=JA%20Asia%20Pacific",
      label: "JA Asia Pacific",
    },
    "JA Europe": {
      title: "JA Europe",
      body: "JA Europe is the regional operating center for JA Worldwide across Europe. As the largest European provider of entrepreneurship education programs, JA Europe brings the public and private sectors together to provide youth with experiences that promote the skills, understanding, and perspective they need to succeed in a global economy.",
      link: "/voices/map-explorer?location=JA%20Europe",
      label: "JA Europe",
    },
    "INJAZ Al-Arab JA MENA": {
      title: "INJAZ Al-Arab JA MENA",
      body: "INJAZ Al-Arab JA MENA is the regional operating center for JA Worldwide across the Middle East and North Africa. INJAZ provides education and training in entrepreneurial skills and teaches programs to develop financial literacy and soft skills that equip young people to run their own businesses and find employment.",
      link: "/voices/map-explorer?location=INJAZ%20Al-Arab%20JA%20MENA",
      label: "INJAZ Al-Arab JA MENA",
    },
    "Junior Achievement USA": {
      title: "Junior Achievement USA",
      body: "Junior Achievement USA is the regional operating center for JA Worldwide across the United States. Focused on empowering young people to own their economic success, JA USA’s experiential programs around work readiness, entrepreneurship, and financial literacy reach more than 2.5 million students annually in kindergarten through high school.",
      link: "/voices/map-explorer?location=Junior%20Achievement%20USA",
      label: "Junior Achievement USA",
    },
  },
};

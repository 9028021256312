import { Routes, Route } from "react-router-dom";

import { Box } from "@mui/system";

import Landing from "./components/Landing/Landing";
import NavBar from "./components/NavBar";
import MapViewContainer from "./components/VoicesView/MapView/MapViewContainer";
import TopicViewContainer from "./components/TopicView/TopicViewContainer";
import ConversationViewContainer from "./components/VoicesView/ConversationView/ConversationViewContainer";
import Insights from "./components/TopicView/Insights";
import Voices from "./components/VoicesView/Voices";
import About from "./components/About/About";
import Process from "./components/About/Process/Process";
import Team from "./components/About/Team/Team";

import Highlights from "./assets/content/highlights.json";
import Topics from "./assets/content/topics.json";

function App() {
  return (
    <div id="app">
      <Box minHeight="100vh">
        <NavBar topics={Topics} />
        <Routes>
          <Route path="/" element={<Landing topics={Topics} highlights={Highlights} />} />
          <Route path="/insights" element={<Insights topics={Topics} />} />
          <Route path="insights">
            <Route
              path=":id"
              element={
                <TopicViewContainer topics={Topics} highlights={Highlights} />
              }
            />
          </Route>
          <Route path="/voices" element={<Voices topics={Topics} />} />
          <Route
            path="voices/map-explorer"
            element={
              <MapViewContainer topics={Topics} highlights={Highlights} />
            }
          />
          <Route
            path="voices/conversation-explorer"
            element={<ConversationViewContainer highlights={Highlights} />}
          />
          <Route path="about" element={<About />} />
          <Route path="about/team" element={<Team />} />
          <Route path="about/process" element={<Process />} />
        </Routes>
      </Box>
    </div>
  );
}

export default App;

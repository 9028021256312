import { Grid, Typography, Link } from "@mui/material";
import StyledCallout from "../callouts/StyledCallout";
import JsxParser from "react-jsx-parser";

function HeaderAndMedia({
  title,
  body,
  media,
  link,
  transparent,
  offset,
  swap,
}) {
  const left = (
    <Grid
      item
      xs={12}
      sm={swap ? 7 : 5}
      paddingLeft={{ xs: 0, sm: swap ? 8 : 0 }}
    >
      <Typography
        variant="h5"
        color="primary.dark"
        sx={{ textAlign: { xs: "center", md: "inherit" } }}
      >
        <JsxParser jsx={title} />
      </Typography>
      <Typography variant="body2" color="primary.dark">
        <JsxParser jsx={body} />
      </Typography>
    </Grid>
  );

  const right = (
    <Grid item xs={12} sm={swap ? 5 : 7} paddingLeft={{ sm: swap ? 0 : 8 }}>
      <center>
        <Link
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
        >
          <img src={media} width="70%" />
        </Link>
      </center>
    </Grid>
  );

  return (
    <StyledCallout offset={offset} transparent={transparent}>
      <Grid container spacing={{ xs: 5, sm: 0 }}>
        {swap ? right : left}
        {swap ? left : right}
      </Grid>
    </StyledCallout>
  );
}

export default HeaderAndMedia;

import * as d3 from "d3";
import SimpleColorConverter from "simple-color-converter";

import { useLocation } from "react-router-dom";
import { MAP } from "../config";

export const calculateHighlightCountByLocation = (speakers, highlights) => {
  const countByLocation = {};

  highlights.forEach((h) => {
    const foundSpeaker = {};
    let location = getSpeaker(speakers, h)?.demographics[
      MAP.demographicId
    ].toLowerCase();

    // TODO: Change to check list in new pipeline format
    if (location) {
      if (!foundSpeaker[location]) {
        if (!countByLocation[location]) countByLocation[location] = 0;
        countByLocation[location]++;
      }
      foundSpeaker[location] = true;
    }
  });

  return countByLocation;
};

/**
 * Valid query args for the map view.
 */
export const MAP_QUERY_ARGS = {
  location: "location",
  hideMap: "hideMap",
  topic: "topic",
  prompt: "prompt",
  year: "year",
};

export const CONVERSATION_YEAR = {
  "Year 1": [1838, 1835, 1836, 1837, 1838, 1839, 1840, 1841, 1842, 1843, 1844, 1845, 1846, 1847, 1848, 1849, 1850, 1851, 1856, 1857, 1866, 1867, 1869, 1873, 1876, 1884, 1895],
  "Year 2": [2822, 2734, 2735, 2737, 2805, 2806, 2807, 2810, 2811, 2812, 2813, 2814, 2815, 2816, 2817, 2818, 2819, 2820, 2821, 2822, 2823, 2920, 2939, 2944],
};
/**
 * Convenience function for getting query args.
 **/
export const useQueryArg = (param) => {
  const queryArg = new URLSearchParams(useLocation().search).get(param);
  return queryArg;
};

const padTimeLeft = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length);
};

/**
 * Returns a formatted time in MM:SS.
 */
export const formatDuration = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainder = Math.round(seconds - minutes * 60.0);
  return `${padTimeLeft(minutes, "0", 2)}:${padTimeLeft(remainder, "0", 2)}`;
};

/**
 * Returns the speaker for a highlight
 */
export const getSpeaker = (speakers, highlight) => {
  const { conversation_id, speaker } = highlight;
  return speakers.find(
    (s) => s.conversation_id === conversation_id && speaker === s.name
  );
};

/**
 * Returns the speaker image source or undefined.
 */
export const getSpeakerImgSrc = (speakers, highlight) => {
  const speaker = getSpeaker(speakers, highlight);
  let src;
  if (speaker) {
    src = `${process.env.PUBLIC_URL}/avatars/${getSpeakerPath(
      speaker.name
    )}.jpg`;
  }

  return src;
};

export const getSpeakerPath = (speakerName) => {
  return speakerName.replaceAll(" ", "-");
};

/**
 * Returns an array of the speaker locations. The first element
 * is the primary location.
 */
export const getSpeakerInfo = (demographic, speakers, highlight) => {
  const speaker = getSpeaker(speakers, highlight);
  if (speaker) {
    const { demographics } = speaker;

    const info = demographics[demographic];

    if (info) {
      if (Array.isArray(info)) {
        // if it's a list, join and capitalize each word
        return info.map((i) => getCamelCase(i)).join(", ");
      } else return getCamelCase(info);
    }
  }
  return null;
};

export const getCamelCase = (str) => {
  // TODO: Remove .toString() when convert to new pipeline format
  const parts = str.toString().split(" ");
  return parts.map((part) => part[0].toUpperCase() + part.slice(1)).join(" ");
};

export const getParentTagFromTagString = (tag) => {
  const parts = tag.split(" :: ");
  return parts[0].trim();
};

export const isColorLight = (hexColor) => {
  // determine if the color is light or dark
  // from https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
  const red = parseInt(`${hexColor[1]}${hexColor[2]}`, 16);
  const green = parseInt(`${hexColor[3]}${hexColor[4]}`, 16);
  const blue = parseInt(`${hexColor[5]}${hexColor[6]}`, 16);
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186;
};

export function muteColor(color, grayscale) {
  let c = d3.hsl(color);

  if (grayscale) {
    const bwColor = new SimpleColorConverter({
      hsl: c,
      to: "hex",
      grayscale: grayscale,
    });
    return `#${bwColor.color}`;
  } else {
    return c.copy({ s: 0.5, l: 0.8 }).formatHex();
  }
}

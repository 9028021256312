import { useEffect } from "react";

import StyledBanner from "../../common/banners/StyledBanner";
import HeaderAndMedia from "../../common/text/HeaderAndMedia";

import StaffCallout from "./StaffCallout";
import Participants from "./Participants";
import Partners from "./Partners";

import { OVERVIEW } from "../config/config";
import { STAFF, PARTICIPANTS, PARTNERS, CTA } from "./config";
const SECTION = "team";

function Team() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const section = OVERVIEW.cards.find((card) => card.link === SECTION);

  return (
    <div style={{ background: "#FFF" }}>
      <StyledBanner
        title={section.title}
        picture={section.picture}
        navigation={OVERVIEW}
      />
      <StaffCallout config={STAFF} />
      <HeaderAndMedia
        title={CTA.title}
        body={CTA.body}
        media={CTA.media}
        link={CTA.link}
        swap
        transparent
        offset
      />
      <Participants config={PARTICIPANTS} offset={-7} />
      <Partners config={PARTNERS} />
    </div>
  );
}

export default Team;

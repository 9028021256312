import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";

function MultipleCheckFilter({
  items,
  defaultName,
  selectedItems,
  setSelectedItems,
  singleColumn,
}) {
  const handleChange = (label) => {
    if (selectedItems.includes(label)) {
      setSelectedItems((prev) => prev.filter((item) => item !== label));
    } else {
      setSelectedItems((prev) => [...prev, label]);
    }
  };

  return (
    <Grid paddingBottom={2}>
      <Typography variant="body1" paddingBottom=".5rem">
        <strong>{defaultName}</strong>
      </Typography>
      <FormGroup>
        <ul className={singleColumn ? "list-single-column" : "list-two-column"}>
          {items.map((item) => {
            return (
              <li>
                <FormControlLabel
                  key={item}
                  control={
                    <Checkbox
                      size="small"
                      checked={selectedItems.includes(item)}
                      sx={{ paddingY: 0.25 }}
                    />
                  }
                  label={<Typography variant="body2">{item.label}</Typography>}
                  onChange={() => handleChange(item)}
                />
              </li>
            );
          })}
        </ul>
      </FormGroup>
    </Grid>
  );
}

export default MultipleCheckFilter;

import { Grid } from "@mui/material";
import Profile from "./Profile";

function ProfileGrid({ config }) {
  return (
    <Grid container spacing={6} paddingY={5}>
      {config.map((person) => (
        <Profile profile={person} key={`about-profile-${person.name}`} />
      ))}
    </Grid>
  );
}

export default ProfileGrid;

// Pictures for each section
import Participants from "../../../assets/landing-page/mosaic.png";

// Partner Logos
import JA from "../../../assets/partners/ja-worldwide-logo.png";
import Accenture from "../../../assets/partners/accenture-logo.png";
import Cortico from "../../../assets/partners/cortico-logo.png";
import CCC from "../../../assets/partners/ccc-logo.png";
import Gather from "../../../assets/logos/gather.png";

export const STAFF = {
  title: "Meet Our JA Youth Advisor Facilitators",
  body: "JA Youth Advisor Conversation Facilitators, all of whom are JA/INJAZ/YE alumni, guided each of the 45+ conversations collected for this initiative.",
  people: [
    {
      name: "Defne Saylik",
      description: "Turkey",
    },
    {
      name: "Noel Bonga",
      description: "Kenya",
    },
    {
      name: "Maia Zennie",
      description: "Lebanon & UAE",
    },
    {
      name: "Raheel Al Abbasi",
      description: "Bahrain",
    },
    {
      name: "Sara Hammoud",
      description: "Lebanon",
    },
    {
      name: "Okechukwu Uche",
      description: "Nigeria/Mauritius",
    },
    {
      name: "Israa Obeid",
      description: "Lebanon",
    },
    {
      name: "Dimas Prima Yoga",
      description: "Indonesia",
    },
    {
      name: "Clint Cadio",
      description: "Canada/Philippines",
    },
    {
      name: "Dana Khairallah",
      description: "Lebanon",
    },
    {
      name: "Abdelghani Bekkouche",
      description: "Algeria",
    },
    {
      name: "Mohamad Al Arnaout",
      description: "Lebanon",
    },
    {
      name: "Wanghley Soares Martins",
      description: "Brazil",
    },
  ],
};

export const CTA = {
  title: "Join Gather Today!",
  media: Gather,
  link: "https://gatheralumni.org",
  body: "Were you a part of JA, Junior Achievement, Young Enterprise, INJAZ, Impulsa, or any of the dozens of ways that JA is translated around the world? If so, you’re eligible to <a className='jsx-link' target='_blank' href='https://gatheralumni.org'>join Gather</a>, the JA alumni community, where doing good is just part of doing business.",
};

export const PARTICIPANTS = {
  title: "Meet Our JA Youth Advisors",
  body: "Pictured and named throughout this portal are the 200+ Youth Advisors, alumni from 64 countries who shared their experiences in 45+ small group conversations, contributing their voices to strengthen the JA strategic plan and share a powerful message about JA values and direction.",
  picture: Participants,
};

export const PARTNERS = {
  title: "Our Partners",
  body: "Additional guidance and support for this project was provided by the JA Worldwide leadership team, Cortico, MIT CCC, and Accenture staff.",
  organizations: [
    {
      name: "JA Worldwide",
      img: JA,
      href: "https://www.jaworldwide.org/",
    },
    {
      name: "Cortico",
      img: Cortico,
      href: "https://cortico.ai/",
    },
    {
      name: "MIT CCC",
      img: CCC,
      href: "https://www.ccc.mit.edu/",
    },
    {
      name: "Accenture",
      img: Accenture,
      href: "https://www.accenture.com/",
    },
  ],
};

import InfoCard from "../../common/cards/InfoCard";

function LocationCard({ locationInfo, transparent, light }) {
  return (
    <InfoCard
      title={locationInfo.title}
      link={locationInfo.link}
      linkLabel={`Explore ${locationInfo.label}`}
      transparent={transparent}
      light={light}
    >
      <p>{locationInfo.body}</p>
    </InfoCard>
  );
}

export default LocationCard;

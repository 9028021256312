import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import LocationCard from "./LocationCard";
import InteractiveMap from "../../VoicesView/MapView/InteractiveMap";
import Locations from "../../../assets/content/locations.json";
import Highlights from "../../../assets/content/highlights.json";

import { MAP } from "../../../config";

function LocationOverviewContainer({ config }) {
  const [currLocation, setCurrLocation] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = (location) => {
    if (currLocation === location) {
      setCurrLocation(null);
    } else {
      setCurrLocation(location);
    }
  };

  const handleClose = () => {
    setCurrLocation(null);
  };

  const locationInfo = config.descriptions[currLocation] ?? config;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        lg={5}
        sx={{
          backgroundColor: "primary.main",
          display: { xs: "none", md: "block" },
        }}
      >
        <Box padding={6}>
          <LocationCard locationInfo={locationInfo} transparent light />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        paddingTop={{ xs: 6, md: 15 }}
        paddingBottom={{ xs: 6, md: 10 }}
        sx={{ backgroundColor: "white" }}
      >
        <InteractiveMap
          currLocation={currLocation}
          locations={Locations}
          highlights={Highlights}
          onClick={handleClick}
          onClose={handleClose}
          noHeight
          locationType={MAP.label}
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={5}
        sx={{
          backgroundColor: "primary.main",
          display: { xs: "block", md: "none" },
        }}
      >
        <Box paddingTop={6} paddingX={{ xs: 3, sm: 6 }} paddingBottom={8}>
          <LocationCard locationInfo={locationInfo} transparent light />
        </Box>
      </Grid>
    </Grid>
  );
}

export default LocationOverviewContainer;

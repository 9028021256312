import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import HoverMenu from "material-ui-popup-state/HoverMenu";

export default function SingleFadeMenu({ label, links, defaultLink }) {
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => {
    popupState.close();
    return navigate(defaultLink, { replace: true });
  }, [navigate, defaultLink]);

  const popupState = usePopupState({
    variant: "popover",
    popupId: "demoMenu",
  });

  return (
    <div>
      <Button
        id="fade-button"
        sx={{ paddingRight: "1.5rem", color: "primary.dark" }}
        {...bindHover(popupState)}
        onClick={handleOnClick}
      >
        {label}
      </Button>
      <HoverMenu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ display: { xs: "none", md: "block" }}}
      >
        {links.map((link) => (
          <Link
            key={`fade-menu-${link.link}`}
            to={link.link}
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: "12px",
            }}
          >
            <MenuItem onClick={popupState.close}>{link.label}</MenuItem>
          </Link>
        ))}
      </HoverMenu>
    </div>
  );
}

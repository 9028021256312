import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import JsxParser from "react-jsx-parser";

import StyledBanner from "../../common/banners/StyledBanner";
import ProcessStep from "./ProcessStep";
import Section from "../../common/background/Section";
import ProcessCardTabContainer from "./ProcessCardTabContainer";

import Introduction from "./Introduction";

import { OVERVIEW } from "../config/config";
import { INTRODUCTION, STEPS } from "./config";
const SECTION = "process";

const Process = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const section = OVERVIEW.cards.find((card) => card.link === SECTION);

  return (
    <div style={{ background: "#FFF" }}>
      <StyledBanner
        title={section.title}
        picture={section.picture}
        navigation={OVERVIEW}
      />
      <Introduction config={INTRODUCTION} />
      <Grid marginTop={-5}>
        {STEPS.map((step, idx) => (
          <>
            <Section>
              <Grid item xs={12} marginBottom={5}>
                <ProcessStep
                  step={idx + 1}
                  title={step.title}
                  imageSrc={step.picture}
                >
                  <JsxParser jsx={step.body} />
                </ProcessStep>
              </Grid>
            </Section>
            {step.sections && (
              <Section texture dark>
                <Box
                  paddingY={6}
                  xs={12}
                  marginTop={{ xs: "-95px", sm: "-90px" }}
                >
                  <ProcessCardTabContainer
                    config={step.sections}
                    label={step.label}
                  />
                </Box>
              </Section>
            )}
          </>
        ))}
      </Grid>
    </div>
  );
};

export default Process;
